/* ClientsPage.css */
.clients-page {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .clients-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .create-button {
    background-color: #C9A77C;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .create-button:hover {
    background-color: #b59668;
  }
  
  .clients-list {
    background-color: white;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  }
  
  .clients-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .clients-table th, 
  .clients-table td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #eee;
  }
  
  /* Modification ici pour rendre l'en-tête du tableau plus visible */
  .clients-table th {
    background-color: #333;
    color: white;
    font-weight: 600;
    padding: 1.2rem 1rem;
  }
  
  .actions-cell {
    display: flex;
    gap: 0.5rem;
  }
  
  .view-button, .edit-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .view-button {
    background-color: #e7f0fd;
    color: #3a76df;
  }
  
  .edit-button {
    background-color: #f8f9fa;
    color: #555;
  }
  
  .view-button:hover {
    background-color: #d6e5fc;
  }
  
  .edit-button:hover {
    background-color: #eee;
  }
  
  .loading, .no-clients {
    text-align: center;
    padding: 2rem;
    color: #666;
  }
  
  .create-client-form {
    background-color: white;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-row {
    display: flex;
    gap: 1rem;
  }
  
  .form-row .form-group {
    flex: 1;
  }
  
  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  
  input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .error-message {
    background-color: #ffe6e6;
    color: #d33;
    padding: 0.75rem;
    border-radius: 4px;
    margin-bottom: 1rem;
  }
  
  .success-message {
    background-color: #e6ffee;
    color: #2d862d;
    padding: 0.75rem;
    border-radius: 4px;
    margin-bottom: 1rem;
  }
  
  small {
    display: block;
    color: #666;
    margin-top: 0.25rem;
    font-size: 0.8rem;
  }
  
  .form-actions {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .submit-button {
    background-color: #C9A77C;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .cancel-button {
    background-color: #f8f9fa;
    color: #555;
    border: 1px solid #ddd;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #b59668;
  }
  
  .cancel-button:hover {
    background-color: #eee;
  }

.quote-button {
    background-color: #C9A77C;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 0.5rem;
  }
  
  .quote-button:hover {
    background-color: #b59668;
  }


  /* Dans ClientsPage.css */
.edit-button {
  padding: 6px 12px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
}

.edit-button:hover {
  background-color: #45a049;
}

.form-row {
  display: flex;
  gap: 15px;
}

.form-row .form-group {
  flex: 1;
}