/* Layout général */
.products-page {
    padding: 2rem;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .main-content {
    display: grid;
    gap: 2rem;
  }
  
  /* En-tête de page */
  .page-header {
    margin-bottom: 2rem;
  }
  
  .page-header h1 {
    color: #2c3e50;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  /* Cartes */
  .card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 2rem;
  }
  
  .card-header {
    background: #f8f9fa;
    padding: 1.5rem;
    border-bottom: 1px solid #e9ecef;
  }
  
  .card-header h2 {
    color: #2c3e50;
    font-size: 1.5rem;
    margin: 0;
  }
  
  .card-body {
    padding: 1.5rem;
    position: relative;
  }
  
  /* Formulaire */
  .form-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .form-group label {
    font-weight: 600;
    color: #495057;
  }
  
  .form-group input {
    padding: 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.2s;
  }
  
  .form-group input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
  
  /* Sections catégories */
  .categories-section {
    margin-bottom: 2rem;
  }
  
  .categories-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .category-path {
    background: #f8f9fa;
    padding: 1rem;
    border-radius: 6px;
    border: 1px solid #e9ecef;
  }
  
  .path-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .path-levels {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;
  }
  
  .level-input {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  /* Photos */
  .photos-section {
    margin-bottom: 2rem;
  }
  
  .photos-upload {
    margin-bottom: 1rem;
  }
  
  .photos-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .photo-item {
    position: relative;
    aspect-ratio: 1;
  }
  
  .photo-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
  
  /* Boutons */
  .btn {
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .btn-primary {
    background: #007bff;
    color: white;
  }
  
  .btn-primary:hover {
    background: #0056b3;
  }
  
  .btn-secondary {
    background: #6c757d;
    color: white;
  }
  
  .btn-secondary:hover {
    background: #5a6268;
  }
  
  .btn-success {
    background: #28a745;
    color: white;
  }
  
  .btn-success:hover {
    background: #218838;
  }
  
  .btn-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.2rem;
    color: white;
  }
  
  .btn-icon.delete {
    background: #dc3545;
  }
  
  .btn-icon.delete:hover {
    background: #c82333;
  }
  
  /* Liste des produits */
  .products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  
  .product-card {
    background: white;
    border: 1px solid #e9ecef;
    border-radius: 6px;
    padding: 1rem;
    transition: box-shadow 0.2s;
  }
  
  .product-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .product-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .product-details {
    display: grid;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .product-info {
    display: flex;
    justify-content: space-between;
    color: #495057;
  }
  
  .info-label {
    font-weight: 600;
  }
  
  .product-categories {
    margin-top: 1rem;
  }
  
  .product-categories ul {
    list-style: none;
    padding: 0;
    margin: 0.5rem 0;
  }
  
  .product-categories li {
    color: #666;
    padding: 0.25rem 0;
  }
  
  /* Loader */
  .loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .loader {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Messages d'erreur */
  .error-alert {
    background: #f8d7da;
    color: #721c24;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .products-page {
      padding: 1rem;
    }
  
    .form-grid {
      grid-template-columns: 1fr;
    }
  
    .photos-grid {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
  
    .product-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
  }
  
  /* Aide visuelle */
  .help-text {
    color: #6c757d;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .product-card {
    animation: fadeIn 0.3s ease-in-out;
  }




  /* Modification de la grille de photos */
.photos-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
  
  /* Style pour les conteneurs d'images */
  .photo-item {
    position: relative;
    width: 100%;
    height: 100px;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #e9ecef;
  }
  
  /* Style pour les images dans la liste des produits */
  .product-photos {
    margin-top: 1rem;
  }
  
  .product-photos .photos-grid {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  }
  
  .photo-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  
  /* Style pour le bouton de suppression d'image */
  .btn-icon.delete {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 20px;
    height: 20px;
    font-size: 14px;
    background: rgba(220, 53, 69, 0.9);
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    padding: 0;
  }
  
  .btn-icon.delete:hover {
    background: rgba(200, 35, 51, 1);
  }
  
  /* Amélioration de l'affichage des images dans les cartes de produits */
  .product-card .photos-grid img {
    width: 100%;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
    border: 1px solid #e9ecef;
  }
  
  /* Style pour la zone de prévisualisation des nouvelles photos */
  .photos-preview {
    margin-top: 1rem;
    padding: 1rem;
    background: #f8f9fa;
    border-radius: 4px;
  }
  
  .photos-preview h4 {
    margin-bottom: 0.5rem;
    color: #495057;
  }
  
  /* Style pour les photos actuelles */
  .current-photos {
    margin-top: 1rem;
    padding: 1rem;
    background: #f8f9fa;
    border-radius: 4px;
  }
  
  .current-photos h4 {
    margin-bottom: 0.5rem;
    color: #495057;
  }
  
  /* Responsive design pour les images */
  @media (max-width: 768px) {
    .photos-grid {
      grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    }
    
    .photo-item {
      height: 70px;
    }
    
    .product-card .photos-grid img {
      height: 60px;
    }
  }






  /* Style pour les boutons d'action des produits */
.product-actions {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .btn-edit {
    background: #007bff;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.2s;
  }
  
  .btn-edit:hover {
    background: #0056b3;
  }
  
  .btn-delete {
    background: #dc3545;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.2s;
  }
  
  .btn-delete:hover {
    background: #c82333;
  }
  
  /* Ajustement du header de la carte produit */
  .product-header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .product-header h3 {
    margin: 0;
    font-size: 1.2rem;
    color: #2c3e50;
  }
  
  /* Style de la carte produit */
  .product-card {
    display: flex;
    flex-direction: column;
    background: white;
    border: 1px solid #e9ecef;
    border-radius: 6px;
    padding: 1rem;
    transition: box-shadow 0.2s;
  }
  
  .product-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .product-actions {
      flex-direction: row;
      justify-content: space-between;
    }
    
    .btn-edit, .btn-delete {
      flex: 1;
      text-align: center;
    }
  }


  /* Styles pour la sélection de catégories */
.categories-checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 200px;
  overflow-y: auto;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.category-checkbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.category-checkbox input[type="checkbox"] {
  margin: 0;
}

/* Style pour le filtre par catégorie */
/* Style pour le filtre par catégorie */
.category-filter {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.category-filter label {
  font-weight: 600;
  color: #495057;
}

.category-filter select {
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  min-width: 250px;
}




/* Style amélioré pour la section formulaire */
.product-form-section .card {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-form-section .card:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.product-form-section .card-header {
  background: linear-gradient(to right, #f8f9fa, #e9ecef);
  padding: 1.5rem;
  border-bottom: 1px solid #e9ecef;
}

.product-form-section .card-body {
  padding: 2rem;
}

/* Modification du form-grid pour disposition en colonne */
.form-grid {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 600px;
  margin: 0 auto;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-weight: 600;
  color: #495057;
  font-size: 0.95rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s ease;
  background-color: #f8f9fa;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
  background-color: white;
}

/* Style pour la section photos */
.photos-section {
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px dashed #e9ecef;
}

.photos-section h3 {
  margin-bottom: 1rem;
  color: #495057;
  font-size: 1.2rem;
}

.photos-upload {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  border: 2px dashed #ced4da;
  margin-bottom: 1.5rem;
  text-align: center;
  transition: all 0.2s ease;
}

.photos-upload:hover {
  border-color: #adb5bd;
}

.file-input {
  width: 100%;
  margin-bottom: 0.5rem;
}

/* Style pour les boutons d'action */
.form-actions {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  justify-content: center;
}

.btn {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
}

.btn-success {
  background: #28a745;
  color: white;
}

.btn-success:hover {
  background: #218838;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(33, 136, 56, 0.3);
}

.btn-secondary {
  background: #6c757d;
  color: white;
}

.btn-secondary:hover {
  background: #5a6268;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(90, 98, 104, 0.3);
}

/* Style amélioré pour les aperçus d'images */
.photos-preview, .current-photos {
  margin-top: 1.5rem;
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
}

.photos-preview h4, .current-photos h4 {
  margin-bottom: 1rem;
  color: #495057;
  font-size: 1rem;
}

.photos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;
}

.photo-item {
  position: relative;
  width: 100%;
  height: 120px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.photo-item:hover {
  transform: scale(1.03);
}

.photo-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.btn-icon.delete {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  background: rgba(220, 53, 69, 0.9);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  border: none;
  padding: 0;
  transition: all 0.2s ease;
}

.btn-icon.delete:hover {
  background: #dc3545;
  transform: scale(1.1);
}

/* Responsive pour mobile */
@media (max-width: 768px) {
  .product-form-section .card-body {
    padding: 1.5rem;
  }
  
  .form-actions {
    flex-direction: column;
  }
  
  .btn {
    width: 100%;
  }
}

.critical-date {
  background-color: #ff4d4f !important;
  color: white !important;
  border-radius: 50%;
}


