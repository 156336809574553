.stock-calendar-container {
    padding: 1.5rem;
    margin: 1.5rem 0;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    font-family: 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
  }
  
  .calendar-title {
    font-size: 1.4rem;
    margin-bottom: 1.2rem;
    color: #333;
    font-weight: 600;
    text-align: center;
  }
  
  /* Calendar styling */
  .stock-calendar {
    width: 100%;
    border: none !important;
    background: transparent !important;
  }
  
  .stock-calendar button {
    font-weight: 500;
    border-radius: 8px !important;
    transition: all 0.2s ease;
  }
  
  .stock-calendar button:hover {
    background-color: #f0f4f9 !important;
  }
  
  .stock-calendar .react-calendar__navigation {
    margin-bottom: 1rem;
  }
  
  .stock-calendar .react-calendar__navigation button {
    border-radius: 8px;
    font-weight: 600;
    color: #2c3e50;
  }
  
  .critical-date-red {
    background-color: #ff4d4f !important;
    color: white !important;
  }
  
  .critical-date-orange {
    background-color: #faad14 !important;
    color: white !important;
  }
  
  .normal-stock {
    background-color: #52c41a16 !important;
  }
  
  /* Stock indicators */
  .stock-indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 auto;
    margin-top: 2px;
  }
  
  .stock-indicator.empty {
    background-color: #ff4d4f;
  }
  
  .stock-indicator.low {
    background-color: #faad14;
  }
  
  .stock-indicator.available {
    background-color: #52c41a;
  }
  
  /* Legend */
  .calendar-legend {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    gap: 1.5rem;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    color: #5a6777;
  }
  
  .legend-color {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 6px;
  }
  
  .legend-color.empty {
    background-color: #ff4d4f;
  }
  
  .legend-color.low {
    background-color: #faad14;
  }
  
  .legend-color.available {
    background-color: #52c41a;
  }
  
  /* Stock info card */
  .stock-info-card {
    margin-top: 1.5rem;
    padding: 1.2rem;
    background-color: #f8fafc;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
    border-left: 4px solid #1890ff;
  }
  
  .stock-info-card h4 {
    font-size: 1.1rem;
    margin-bottom: 0.8rem;
    color: #1890ff;
    font-weight: 600;
  }
  
  .selected-date, .stock-quantity {
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .stock-status {
    display: inline-flex;
    align-items: center;
    padding: 0.4rem 0.8rem;
    border-radius: 6px;
    font-weight: 500;
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }
  
  .stock-status svg {
    margin-right: 6px;
  }
  
  .stock-status.in-stock {
    background-color: #f6ffed;
    color: #52c41a;
    border: 1px solid #b7eb8f;
  }
  
  .stock-status.low-stock {
    background-color: #fff7e6;
    color: #faad14;
    border: 1px solid #ffd591;
  }
  
  .stock-status.no-stock {
    background-color: #fff2f0;
    color: #ff4d4f;
    border: 1px solid #ffccc7;
  }
  
  /* Loading and error states */
  .loading-container, .error-container {
    padding: 2rem;
    text-align: center;
    background-color: #f8fafc;
    border-radius: 8px;
    color: #5a6777;
  }
  
  .error-container {
    color: #ff4d4f;
    border: 1px solid #ffccc7;
  }