.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
  }
  
  .login-box {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .login-title {
    text-align: center;
    color: #333;
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .form-group label {
    color: #555;
    font-weight: 500;
  }
  
  .form-group input {
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    transition: border-color 0.3s;
  }
  
  .form-group input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
  }
  
  .login-button {
    background-color: #007bff;
    color: white;
    padding: 0.8rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    background-color: #fff3f3;
    color: #dc3545;
    padding: 0.8rem;
    border-radius: 5px;
    margin-bottom: 1rem;
    text-align: center;
  }


  .company-name {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .company-name h1 {
    color: #007bff;
    font-size: 2.5rem;
    margin: 0;
    font-weight: 700;
  }
  
  .company-subtitle {
    color: #666;
    margin: 0.5rem 0 0 0;
    font-size: 1rem;
  }
  
  /* Modifier la taille du titre de connexion puisque ce n'est plus le titre principal */
  .login-title {
    font-size: 1.5rem;
    margin-top: 0;
  }
  
  /* Ajuster la taille de la box pour accommoder le nouveau contenu */
  .login-box {
    padding: 2.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }