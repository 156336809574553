.home-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .home-header {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .welcome-text {
    color: #666;
    font-size: 1.1rem;
    margin-top: 1rem;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 1rem;
  }
  
  .feature-card {
    background: white;
    border-radius: 8px;
    padding: 1.5rem;
    text-decoration: none;
    color: inherit;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.15);
  }
  
  .feature-icon {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .feature-card h2 {
    margin: 0.5rem 0;
    font-size: 1.25rem;
    color: #333;
  }
  
  .feature-card p {
    color: #666;
    margin: 0;
    font-size: 0.9rem;
  }



/* Pour un calendrier unique, centré */
.calendar-wrapper {
  width: 100%;
  max-width: 340px; /* Taille réduite pour un seul mois */
  margin: 0 auto;
}

.custom-calendar {
  width: 100% !important;
}

.react-datepicker__month-container {
  width: 100%;
  margin: 0 !important;
}

/* Ajuster le positionnement des boutons de navigation */
.prev-button {
  left: -15px;
}

.next-button {
  right: -15px;
}


/* Ajoutez ces styles à votre CreateQuotePage.css pour élargir le calendrier */

/* Augmenter la largeur du calendrier */
.calendar-wrapper {
  width: 100%;
  max-width: 400px; /* Augmenté pour un calendrier plus large */
  margin: 0 auto;
}

/* Assurer que le calendrier utilise tout l'espace disponible */
.react-datepicker {
  width: 100% !important;
}

/* Ajuster la largeur du conteneur de mois */
.react-datepicker__month-container {
  width: 100% !important;
}

/* Améliorer l'affichage des jours */
.react-datepicker__day {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  margin: 2px !important;
  border-radius: 50% !important;
  font-size: 14px !important;
}

/* Ajuster les noms des jours de la semaine */
.react-datepicker__day-name {
  width: 40px !important;
  margin: 2px !important;
  font-size: 14px !important;
}

/* S'assurer que les semaines utilisent tout l'espace horizontal */
.react-datepicker__week {
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
}

/* Adapter l'en-tête du mois */
.react-datepicker__header {
  padding: 15px 0 !important;
}

/* Style pour l'en-tête du mois */
.react-datepicker__current-month,
.month-year-label {
  font-size: 18px !important;
  margin-bottom: 10px !important;
}