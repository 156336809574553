.admin-rentals-page {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f8f9fa;
  }
  
  h1 {
    color: #2c3e50;
    margin-bottom: 2rem;
    font-size: 2rem;
    border-bottom: 3px solid #3498db;
    padding-bottom: 0.5rem;
  }
  
  /* Table styles */
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    overflow: hidden;
  }
  
  thead {
    background-color: #3498db;
    color: white;
  }
  
  th {
    padding: 1.2rem 1rem;
    text-align: left;
    font-weight: 600;
    font-size: 0.95rem;
    letter-spacing: 0.5px;
  }
  
  td {
    padding: 1rem;
    border-bottom: 1px solid #eee;
    font-size: 0.9rem;
  }
  
  tbody tr:last-child td {
    border-bottom: none;
  }
  
  tbody tr {
    transition: background-color 0.2s;
  }
  
  tbody tr:hover {
    background-color: #f8f9fa;
  }
  
  /* Button styles */
  button {
    padding: 0.6rem 1.2rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s;
    margin: 0 0.5rem;
    font-size: 0.9rem;
  }
  
  button:first-of-type {
    background-color: #e74c3c;
    color: white;
  }
  
  button:last-of-type {
    background-color: #3498db;
    color: white;
  }
  
  button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  button:active {
    transform: translateY(0);
  }
  
  /* Status styles */
  td[data-status] {
    font-weight: 600;
  }
  
  td[data-status="pending"] {
    color: #f39c12;
  }
  
  td[data-status="validated"] {
    color: #2ecc71;
  }
  
  td[data-status="refused"] {
    color: #e74c3c;
  }
  
  /* Pagination */
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    margin-top: 2rem;
  }
  
  .pagination button {
    background-color: #3498db;
    color: white;
    padding: 0.8rem 1.5rem;
  }
  
  .pagination span {
    font-weight: 500;
    color: #2c3e50;
  }
  
  /* Modal styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(4px);
  }
  
  .modal {
    background: white;
    border-radius: 12px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #eee;
    background-color: #f8f9fa;
  }
  
  .modal-header h2 {
    margin: 0;
    color: #2c3e50;
    font-size: 1.5rem;
  }
  
  .modal-content {
    padding: 2rem;
  }
  
  .modal-content p {
    margin: 1rem 0;
    line-height: 1.6;
  }
  
  .refusal-message {
    margin-top: 1.5rem;
  }
  
  .refusal-message label {
    display: block;
    margin-bottom: 0.8rem;
    color: #2c3e50;
    font-weight: 500;
  }
  
  .refusal-message textarea {
    width: 100%;
    min-height: 120px;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 6px;
    resize: vertical;
    font-family: inherit;
    font-size: 0.95rem;
    line-height: 1.5;
  }
  
  .refusal-message textarea:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  }
  
  .modal-footer {
    padding: 1.5rem 2rem;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    background-color: #f8f9fa;
  }
  
  .modal-footer button:last-child {
    background-color: #95a5a6;
  }







  /* Styles spécifiques pour la modale de devis */
.quote-modal {
  max-width: 800px;
}

.quote-items-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1.5rem 0;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.quote-items-table th {
  background-color: #3498db;
  color: white;
  padding: 1rem;
  text-align: left;
  font-weight: 600;
}

.quote-items-table td {
  padding: 0.8rem 1rem;
  border-bottom: 1px solid #eee;
}

.quote-items-table tbody tr:last-child td {
  border-bottom: 2px solid #ddd;
}

.quote-items-table input {
  width: 80px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: right;
}

.quote-items-table .total-label,
.quote-items-table .deposit-label {
  text-align: right;
  font-weight: 600;
  padding-right: 2rem;
}

.quote-items-table .total-amount,
.quote-items-table .deposit-amount {
  font-weight: 700;
  color: #2c3e50;
}

.quote-items-table .deposit-label,
.quote-items-table .deposit-amount {
  color: #e67e22;
}

/* Styles pour les boutons d'action */
.modal-footer button:nth-child(1) {
  background-color: #2ecc71;
}

.modal-footer button:nth-child(2) {
  background-color: #3498db;
}

/* Pour distinguer clairement le bouton Modifier */
.modal-footer button:nth-child(1):not(:only-child) {
  background-color: #f39c12;
}








/* Styles pour la version améliorée du devis */
.quote-modal {
  max-width: 900px;
  width: 90%;
  max-height: 85vh;
}

.quote-preview {
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* En-tête du devis avec logo et infos */
.quote-header-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 2px solid #eee;
}

.quote-company-info {
  display: flex;
  align-items: flex-start;
}

.company-logo {
  width: 150px;
  height: auto;
  margin-right: 20px;
}

.company-details h3 {
  margin-top: 0;
  color: #2c3e50;
}

.company-details p {
  margin: 5px 0;
  font-size: 14px;
}

.quote-reference {
  text-align: right;
}

.quote-reference h2 {
  color: #3498db;
  margin: 0 0 15px 0;
  font-size: 28px;
}

.quote-reference p {
  margin: 5px 0;
}

/* Infos client */
.quote-client-info {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 20px;
}

.quote-client-info h3 {
  margin-top: 0;
  color: #2c3e50;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
}

/* Table des articles */
.quote-items-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.quote-items-table th {
  background-color: #3498db;
  color: white;
  padding: 12px;
  text-align: left;
}

.quote-items-table td {
  padding: 10px 12px;
  border-bottom: 1px solid #eee;
}

.quote-items-table input {
  width: 80px;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.quote-items-table tfoot tr td {
  font-weight: 600;
  padding: 10px 12px;
}

.total-row td {
  font-weight: 700;
  color: #2c3e50;
  background-color: #f8f9fa;
}

.deposit-row td {
  color: #e67e22;
}

.delivery-row {
  background-color: #f5f5f5;
}

/* Conditions de vente */
.quote-terms {
  margin-top: 30px;
  font-size: 14px;
  color: #7f8c8d;
}

.quote-terms h3 {
  color: #2c3e50;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
}

/* Section d'édition de la livraison */
.delivery-edit-section {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 20px;
  border-left: 3px solid #3498db;
}

.delivery-edit-section h3 {
  margin-top: 0;
  color: #2c3e50;
}

.input-group {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.input-group label {
  margin-right: 10px;
  min-width: 200px;
}

.input-group input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100px;
}

/* Boutons spécifiques */
.edit-btn {
  background-color: #f39c12;
}

.download-btn {
  background-color: #2ecc71;
}

.send-btn {
  background-color: #3498db;
}

.save-btn {
  background-color: #2ecc71;
}

.cancel-btn {
  background-color: #95a5a6;
}

.close-btn {
  background-color: #7f8c8d;
}

.quote-btn, .refuse-btn {
  margin-right: 5px;
  padding: 0.5rem 1rem;
}

.quote-btn {
  background-color: #2980b9;
}

.refuse-btn {
  background-color: #e74c3c;
}









/* Styles pour la facture */
.invoice-modal {
  width: 90%;
  max-width: 900px;
}

.invoice-preview {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.invoice-header-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.invoice-company-info {
  display: flex;
  align-items: flex-start;
}

.company-logo {
  height: 80px;
  margin-right: 20px;
}

.company-details h3 {
  margin-top: 0;
  color: #333;
}

.invoice-reference {
  text-align: right;
}

.invoice-reference h2 {
  margin-top: 0;
  color: #C9A77C;
}

.invoice-client-info {
  margin-bottom: 30px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.invoice-client-info h3 {
  margin-top: 0;
  color: #333;
}

.invoice-details h3 {
  margin-top: 0;
  color: #333;
}

.invoice-items-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.invoice-items-table th {
  background-color: #C9A77C;
  color: white;
  padding: 10px;
  text-align: left;
}

.invoice-items-table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.delivery-row {
  background-color: #f5f5f5;
}

.total-row {
  font-weight: bold;
  background-color: #f0f0f0;
}

.invoice-terms {
  margin-top: 30px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.invoice-terms h3 {
  margin-top: 0;
  color: #333;
}

/* Boutons spécifiques à la facture */
.invoice-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
}

.invoice-btn:hover {
  background-color: #45a049;
}











/* Styles pour les différents statuts */
tr[data-status="en attente"] {
  background-color: #f8f4e9; /* beige clair pour les nouvelles demandes */
}

tr[data-status="devis envoyé par mail"] {
  background-color: #e8f4fd; /* bleu très clair pour les devis envoyés */
}

tr[data-status="paid"] {
  background-color: #e6f7e9; /* vert clair pour les devis acceptés et accompte payé */
}

tr[data-status="décliner"] {
  background-color: #fceae9; /* rouge très clair pour les devis refusés par le client */
}

tr[data-status="facture envoyée"] {
  background-color: #f0f4c3; /* jaune très clair pour les factures envoyées */
}

tr[data-status="refused"] {
  background-color: #f9e0de; /* rose clair pour les demandes refusées par l'admin */
}

/* Styles pour le texte du statut */
td[data-status="en attente"] {
  color: #9e9d24; /* jaune foncé */
  font-weight: 600;
}

td[data-status="devis envoyé par mail"] {
  color: #1976d2; /* bleu */
  font-weight: 600;
}

td[data-status="paid"] {
  color: #2e7d32; /* vert foncé */
  font-weight: 600;
}

td[data-status="décliner"] {
  color: #d32f2f; /* rouge */
  font-weight: 600;
}

td[data-status="facture envoyée"] {
  color: #f57c00; /* orange */
  font-weight: 600;
}

td[data-status="refused"] {
  color: #c2185b; /* rose foncé */
  font-weight: 600;
}

/* Effet de survol qui conserve une teinte de la couleur de statut */
tr[data-status="en attente"]:hover {
  background-color: #f0ecd8;
}

tr[data-status="devis envoyé par mail"]:hover {
  background-color: #d6ebfc;
}

tr[data-status="paid"]:hover {
  background-color: #d4f0d9;
}

tr[data-status="décliner"]:hover {
  background-color: #fad8d5;
}

tr[data-status="facture envoyée"]:hover {
  background-color: #e8ecb0;
}

tr[data-status="refused"]:hover {
  background-color: #f7d0cc;
}



/* Styles pour la légende */
.status-legend {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.legend-item {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.legend-color {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 4px;
}

.legend-color[data-status="en attente"] {
  background-color: #f8f4e9;
  border: 1px solid #9e9d24;
}

.legend-color[data-status="devis envoyé par mail"] {
  background-color: #e8f4fd;
  border: 1px solid #1976d2;
}

.legend-color[data-status="paid"] {
  background-color: #e6f7e9;
  border: 1px solid #2e7d32;
}

.legend-color[data-status="décliner"] {
  background-color: #fceae9;
  border: 1px solid #d32f2f;
}

.legend-color[data-status="facture envoyée"] {
  background-color: #f0f4c3;
  border: 1px solid #f57c00;
}

.legend-color[data-status="refused"] {
  background-color: #f9e0de;
  border: 1px solid #c2185b;
}

/* Style pour l'indicateur de chargement */
.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0;
  padding: 10px;
  background-color: rgba(0,0,0,0.05);
  border-radius: 5px;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0,0,0,0.1);
  border-radius: 50%;
  border-top-color: #C9A77C;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Style pour l'édition en ligne de la livraison */
.delivery-edit-inline {
  display: flex;
  align-items: center;
}

.small-input {
  width: 50px;
  padding: 2px 5px;
  margin: 0 5px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.spinner-inline {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(0,0,0,0.1);
  border-radius: 50%;
  border-top-color: #C9A77C;
  animation: spin 1s linear infinite;
  margin-left: 8px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Styles pour les lignes d'acompte et de reste à payer */
.paid-deposit-row td {
  color: #2c8a2c;
  font-style: italic;
}

.remaining-amount-row td {
  font-weight: bold;
  color: #d9534f;
}

/* Améliorations générales pour la facture */
.invoice-preview {
  padding: 20px;
}

.invoice-header-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.invoice-company-info {
  display: flex;
  align-items: flex-start;
}

.company-logo {
  max-width: 200px;
  height: auto;
  margin-right: 20px;
}

.company-details h3 {
  margin-top: 0;
  color: #333;
}

.invoice-reference {
  text-align: right;
}

.invoice-reference h2 {
  margin-top: 0;
  color: #4a89dc;
}

.invoice-client-info,
.invoice-details,
.invoice-terms {
  margin-bottom: 30px;
}

.invoice-items-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.invoice-items-table th {
  background-color: #f6f6f6;
  padding: 10px;
  text-align: left;
  border-bottom: 2px solid #ddd;
}

.invoice-items-table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.total-row td {
  font-weight: bold;
  border-top: 2px solid #ddd;
}

.invoice-terms {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
}

/* Styles pour les lignes d'acompte et de reste à payer - maintenant en noir */
.paid-deposit-row td {
  color: #000000;
  font-style: italic;
}

.remaining-amount-row td {
  font-weight: bold;
  color: #000000;
}


.status-progress {
  position: relative;
  width: 100%;
}

.progress-bar {
  height: 8px;
  background-color: #f1f1f1;
  border-radius: 4px;
  margin-bottom: 5px;
}

.progress-fill {
  height: 100%;
  background-color: #C9A77C;
  border-radius: 4px;
  transition: width 0.3s ease;
}

.status-progress.canceled .progress-bar {
  background-color: #ffebee;
}

.status-progress.canceled .progress-fill {
  background-color: #e74c3c;
}



/* Style commun pour tous les boutons d'action */
.action-btn {
  margin: 3px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  color: white;
}

.action-btn:hover {
  transform: translateY(-2px);
}

/* Style spécifique pour chaque type de bouton */
.action-btn {
  margin: 3px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  background-color: #3f51b5; /* Couleur principale */
  color: white;
  transition: background-color 0.3s, transform 0.2s;
}

.action-btn:hover {
  background-color: #303f9f; /* Version plus foncée au survol */
  transform: translateY(-2px);
}

/* Ajoutez ou modifiez ces styles dans votre CSS */
.invoice-company-info .company-logo {
  max-width: 200px;
  height: auto;
  margin-right: 20px;
  object-fit: contain; /* Assure que l'image s'adapte correctement */
  display: block; /* S'assure que l'image est traitée comme un bloc */
}


.details-section {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.details-items-table {
  width: 100%;
  border-collapse: collapse;
  margin: 15px 0;
}

.details-items-table th {
  background-color: #f6f6f6;
  color: #333;
  padding: 10px;
  text-align: left;
  border-bottom: 2px solid #ddd;
}

.details-items-table td {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.price-summary {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  border-left: 3px solid #3498db;
}

.delivery-details {
  margin-top: 10px;
  padding-left: 15px;
  border-left: 2px solid #ddd;
}

.refusal-details {
  margin-top: 20px;
  padding: 15px;
  background-color: #fff8f8;
  border-radius: 8px;
  border-left: 3px solid #e74c3c;
}

.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 600;
}

.status-badge[data-status="en attente"] {
  background-color: #f8f4e9;
  color: #9e9d24;
}

.status-badge[data-status="devis envoyé par mail"] {
  background-color: #e8f4fd;
  color: #1976d2;
}

.status-badge[data-status="paid"] {
  background-color: #e6f7e9;
  color: #2e7d32;
}

.status-badge[data-status="décliner"] {
  background-color: #fceae9;
  color: #d32f2f;
}

.status-badge[data-status="facture envoyée"] {
  background-color: #f0f4c3;
  color: #f57c00;
}

.status-badge[data-status="refused"] {
  background-color: #f9e0de;
  color: #c2185b;
}


/* Styles pour la modale de refus */
.refusal-info {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.refusal-message {
  margin-top: 20px;
}

.refusal-message label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
}

.refusal-message .required {
  color: #e74c3c;
}

.refusal-message .help-text {
  font-size: 14px;
  color: #7f8c8d;
  margin-bottom: 10px;
}

.refusal-message textarea {
  width: 100%;
  min-height: 120px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.5;
  resize: vertical;
}

.refusal-message textarea:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.refuse-confirm-btn {
  background-color: #e74c3c !important;
  color: white;
}

.refuse-confirm-btn:disabled {
  background-color: #ddd !important;
  cursor: not-allowed;
  opacity: 0.7;
}

.cancel-btn {
  background-color: #95a5a6 !important;
  color: white;
}


/* Style amélioré pour les options d'édition */
.edit-options {
  margin: 15px 0;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  border-left: 3px solid #C9A77C;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.edit-options h4 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #2c3e50;
  font-size: 16px;
}

.option-checkbox {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 6px;
  background-color: white;
  margin-bottom: 8px;
  transition: background-color 0.2s;
  cursor: pointer;
}

.option-checkbox:hover {
  background-color: #f0f0f0;
}

.option-checkbox input[type="checkbox"] {
  margin-right: 12px;
  width: 18px;
  height: 18px;
  accent-color: #C9A77C;
}

.option-label {
  flex: 1;
}

.option-title {
  font-weight: 600;
  color: #333;
  margin-bottom: 2px;
}

.option-description {
  font-size: 13px;
  color: #777;
  margin: 0;
}

.option-price {
  font-weight: 600;
  color: #C9A77C;
  padding-left: 10px;
}