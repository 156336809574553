.navbar {
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem 2rem;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .navbar-brand {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: 700;
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .logo:hover {
    color: #0056b3;
  }
  
  .navbar-burger {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    position: relative;
    height: 30px;
    width: 30px;
  }
  
  .navbar-burger span {
    background-color: #333;
    display: block;
    height: 2px;
    width: 100%;
    position: absolute;
    left: 0;
    transition: all 0.3s ease-in-out;
  }
  
  .navbar-burger span:nth-child(1) { top: 25%; }
  .navbar-burger span:nth-child(2) { top: 50%; }
  .navbar-burger span:nth-child(3) { top: 75%; }
  
  .navbar-burger.is-active span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  
  .navbar-burger.is-active span:nth-child(2) {
    opacity: 0;
  }
  
  .navbar-burger.is-active span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  
  .navbar-links {
    display: flex;
    gap: 2rem;
    align-items: center;
  }
  
  .nav-link {
    color: #333;
    text-decoration: none;
    font-weight: 500;
    padding: 0.5rem 0;
    position: relative;
    transition: color 0.3s ease;
  }
  
  .nav-link::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #007bff;
    transition: width 0.3s ease;
  }
  
  .nav-link:hover {
    color: #007bff;
  }
  
  .nav-link:hover::after,
  .nav-link.active::after {
    width: 100%;
  }
  
  .nav-link.active {
    color: #007bff;
  }
  

  /* Navbar.css */

.navbar-logout {
    margin-left: auto;
    padding: 0.5rem;
  }
  
  .logout-button {
    background-color: #ff4d4f;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .logout-button:hover {
    background-color: #ff7875;
  }
  
  @media (max-width: 768px) {
    .navbar-burger {
      display: block;
    }
  
    .navbar-menu {
      display: none;
      width: 100%;
      position: absolute;
      top: 100%;
      left: 0;
      background: white;
      padding: 1rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  
    .navbar-menu.is-active {
      display: block;
    }
  
    .navbar-links {
      flex-direction: column;
      gap: 1rem;
    }
  
    .nav-link {
      display: block;
      width: 100%;
      text-align: center;
    }
  }


