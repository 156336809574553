/* CategoriesPage.css */

.categories-page {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .categories-page h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .error-alert {
    background-color: #ffdddd;
    color: #d8000c;
    padding: 10px;
    border: 1px solid #d8000c;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Section de création de hiérarchie */
  .create-hierarchy {
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
  
  .create-hierarchy h2 {
    text-align: center;
    color: #333;
    margin-bottom: 15px;
  }
  
  /* Section de modification de hiérarchie */
  .edit-hierarchy {
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
  
  .edit-hierarchy h2 {
    text-align: center;
    color: #333;
    margin-bottom: 15px;
  }
  
  /* Liste des catégories existantes */
  .existing-categories {
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
  
  .existing-categories h2 {
    text-align: center;
    color: #333;
    margin-bottom: 15px;
  }
  
  .existing-categories ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .existing-categories li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid #eee;
  }
  
  .existing-categories li span {
    font-size: 16px;
    color: #333;
  }
  
  .existing-categories li button {
    background-color: #0984e3;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 6px 10px;
    margin-left: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .existing-categories li button:hover {
    background-color: #74b9ff;
  }
  
  /* Styles pour les champs de saisie de niveau */
  .level-input {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .level-input input[type="text"] {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .level-input button {
    margin-left: 8px;
    background-color: #e17055;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .level-input button:hover {
    background-color: #d63031;
  }
  
  /* Boutons généraux */
  button {
    cursor: pointer;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    margin: 5px 0;
    transition: background-color 0.3s ease;
  }
  
  button[type="button"] {
    background-color: #00b894;
    color: #fff;
  }
  
  button[type="button"]:hover {
    background-color: #00a485;
  }
  
  /* Bouton pour la sauvegarde de l'édition */
  .edit-hierarchy button {
    background-color: #0984e3;
    color: #fff;
  }
  
  .edit-hierarchy button:hover {
    background-color: #74b9ff;
  }
  





  /* CategoriesPage.css */
.categories-page {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .categories-page h1,
  .categories-page h2 {
    text-align: center;
    color: #333;
    margin-bottom: 15px;
  }
  
  .error-alert {
    background-color: #ffdddd;
    color: #d8000c;
    border: 1px solid #d8000c;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Section de création/modification */
  .create-hierarchy, .edit-hierarchy, .existing-categories, .positions-section {
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
  
  .level-input {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .level-input input[type="text"] {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .level-input button {
    margin-left: 8px;
    background-color: #e17055;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .level-input button:hover {
    background-color: #d63031;
  }
  
  button {
    cursor: pointer;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    margin-top: 5px;
    transition: background-color 0.3s ease;
    background-color: #00b894;
    color: #fff;
  }
  
  button:hover {
    background-color: #00a485;
  }
  
  .existing-categories ul,
  .positions-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .existing-categories li,
  .positions-section li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid #eee;
  }
  
  .existing-categories li span,
  .positions-section li span {
    font-size: 16px;
    color: #333;
  }
  
  .positions-section li input[type="number"] {
    width: 80px;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  