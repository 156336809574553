/* CreateQuotePage.css */
.create-quote-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .create-quote-page h1 {
    color: #333;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .quote-form-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .form-section {
    margin-bottom: 30px;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
  }
  
  .form-section h2 {
    color: #C9A77C;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 15px;
  }
  
  .form-group {
    flex: 1;
  }
  
  label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="date"],
  input[type="number"],
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  input[type="radio"] {
    margin-right: 8px;
  }
  
  /* Styles pour le sélecteur de dates */
  .date-picker-container {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .date-picker-container label {
    display: block;
    margin-bottom: 15px;
    font-weight: 500;
    color: #333;
  }
  
  /* Conteneur pour le calendrier avec sa navigation */
  .calendar-with-navigation {
    position: relative;
    max-width: 680px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Style des boutons de navigation du calendrier */
  .calendar-nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #C9A77C;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    z-index: 10;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transition: all 0.2s ease;
  }
  
  .calendar-nav-button:hover {
    background-color: #b59668;
    transform: translateY(-50%) scale(1.05);
  }
  
  .prev-button {
    left: -20px;
  }
  
  .next-button {
    right: -20px;
  }
  
  /* Conteneur du calendrier */
  .calendar-wrapper {
    width: 100%;
  }
  
  /* Style pour le calendrier */
  .custom-calendar {
    width: 100% !important;
  }
  
  /* Masquer les boutons de navigation originaux du datepicker */
  .react-datepicker__navigation {
    display: none !important;
  }
  
  /* Style pour l'en-tête personnalisé des mois */
  .custom-calendar-header {
    display: flex;
    justify-content: center;
    padding: 8px 0;
  }
  
  .month-year-label {
    font-size: 16px;
    font-weight: bold;
    color: white;
    text-transform: capitalize;
  }
  
  /* Améliorer la visibilité des mois côte à côte */
  .react-datepicker__month-container {
    margin: 0 5px;
    width: 50%;
  }
  
  .react-datepicker {
    margin: 0 auto !important;
    width: auto !important;
    display: flex !important;
    justify-content: center !important;
  }
  
  .react-datepicker__month-container {
    float: none !important; 
    display: inline-block !important;
    margin: 0 5px !important;
  }
  
  .react-datepicker__header {
    width: 100% !important;
    text-align: center !important;
    background-color: #C9A77C !important;
    border-bottom: none !important;
    padding: 10px 0 !important;
  }
  
  .react-datepicker__month {
    margin: 0 !important;
    padding: 10px 0 !important;
  }
  
  .react-datepicker__day-names, 
  .react-datepicker__week {
    display: flex !important;
    justify-content: space-around !important;
  }
  
  .react-datepicker__day--selected, 
  .react-datepicker__day--in-selecting-range, 
  .react-datepicker__day--in-range {
    background-color: #C9A77C;
    border-radius: 4px;
    color: white;
  }
  
  .react-datepicker__day--keyboard-selected {
    background-color: rgba(201, 167, 124, 0.5);
  }
  
  .react-datepicker__day:hover {
    background-color: rgba(201, 167, 124, 0.2);
    border-radius: 4px;
  }
  
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
    background-color: rgba(201, 167, 124, 0.7);
  }
  
  .react-datepicker__current-month {
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
    margin-bottom: 10px;
  }
  
  .react-datepicker__day-name {
    color: white;
    font-weight: 500;
    width: 36px;
    margin: 5px;
  }
  
  .react-datepicker__day {
    width: 36px;
    height: 36px;
    line-height: 36px;
    margin: 5px;
    border-radius: 50%;
  }
  
  .selected-dates-summary {
    margin-top: 20px;
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .date-info {
    flex: 1;
    min-width: 180px;
  }
  
  .date-label {
    font-weight: 600;
    margin-bottom: 5px;
    color: #666;
  }
  
  .date-value {
    font-size: 1.1rem;
    color: #333;
  }
  
  .highlight {
    padding: 10px;
    background-color: rgba(201, 167, 124, 0.1);
    border-radius: 6px;
    border-left: 3px solid #C9A77C;
  }
  
  /* Styles pour le sélecteur de mode de livraison */
  .delivery-method-selector {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .delivery-option {
    flex: 1;
    border: 2px solid #eee;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .delivery-option:hover {
    border-color: #ddd;
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.05);
  }
  
  .delivery-option.selected {
    border-color: #C9A77C;
    background-color: rgba(201, 167, 124, 0.1);
  }
  
  .delivery-option h3 {
    margin: 10px 0;
    color: #333;
  }
  
  .delivery-option p {
    color: #666;
    margin: 0;
  }
  
  .icon-container {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #C9A77C;
  }
  
  .delivery-option.selected .icon-container {
    color: #b59668;
  }
  
  .delivery-address-form {
    margin-top: 20px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 6px;
  }
  
  .delivery-address-form h3 {
    margin-top: 0;
    margin-bottom: 15px;
    color: #333;
  }
  
  .distance-input-container {
    display: flex;
    gap: 10px;
  }
  
  .calculate-distance-btn {
    padding: 10px 15px;
    background-color: #C9A77C;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .calculate-distance-btn:hover {
    background-color: #b59668;
  }
  
  .delivery-price-display,
  .duration-display {
    padding: 10px;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-weight: 500;
  }
  
  .product-dropdown-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .product-dropdown {
    flex: 1;
  }
  
  .add-product-btn {
    padding: 10px 15px;
    background-color: #C9A77C;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-product-btn:hover {
    background-color: #b59668;
  }
  
  .selected-products-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .selected-products-table th,
  .selected-products-table td {
    padding: 12px;
    border-bottom: 1px solid #eee;
    text-align: left;
  }
  
  .selected-products-table th {
    background-color: #333;
    color: white;
    font-weight: 600;
  }
  
  .text-right {
    text-align: right;
  }
  
  .text-center {
    text-align: center;
  }
  
  .quantity-cell {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-start;
  }
  
  .quantity-btn {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eee;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .quantity-cell input {
    width: 60px;
    text-align: center;
  }
  
  .remove-btn {
    padding: 8px 12px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .remove-btn:hover {
    background-color: #d32f2f;
  }
  
  .no-products-selected {
    padding: 30px;
    text-align: center;
    color: #666;
    background-color: #f8f9fa;
    border-radius: 6px;
  }
  
  .total-row,
  .deposit-row {
    font-weight: bold;
  }
  
  .total-row td {
    color: #C9A77C;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }
  
  .preview-btn {
    padding: 12px 24px;
    background-color: #C9A77C;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
  }
  
  .preview-btn:hover {
    background-color: #b59668;
  }
  
  /* Styles pour l'aperçu du devis */
  .quote-preview-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .quote-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  
  .back-btn,
  .create-btn {
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
  }
  
  .back-btn {
    background-color: #f8f9fa;
    color: #333;
    border: 1px solid #ddd;
  }
  
  .create-btn {
    background-color: #C9A77C;
    color: white;
  }
  
  .back-btn:hover {
    background-color: #eee;
  }
  
  .create-btn:hover {
    background-color: #b59668;
  }
  
  .quote-preview {
    padding: 40px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: white;
  }
  
  .quote-header-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
  }
  
  .quote-company-info {
    display: flex;
    gap: 20px;
  }
  
  .company-logo {
    max-width: 200px;
    height: auto;
    object-fit: contain;
  }
  
  .quote-reference h2 {
    color: #C9A77C;
    margin-bottom: 15px;
  }
  
  .quote-client-info,
  .quote-details {
    margin-bottom: 30px;
  }
  
  .quote-client-info h3,
  .quote-details h3,
  .quote-terms h3 {
    color: #333;
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
  
  .quote-items-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .quote-items-table th,
  .quote-items-table td {
    padding: 12px;
    border-bottom: 1px solid #eee;
  }
  
  .quote-items-table th {
    background-color: #333;
    color: white;
    font-weight: 600;
  }
  
  .quote-terms {
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid #eee;
    color: #666;
    font-size: 0.9rem;
  }
  
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    font-size: 18px;
    color: #666;
  }
  
  .client-info-readonly {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
  }
  
  .client-details-summary {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .client-details-summary p {
    margin: 5px 0;
  }
  
  /* Styles responsifs */
  @media (max-width: 768px) {
    .form-row {
      flex-direction: column;
      gap: 15px;
    }
    
    .quote-header-section {
      flex-direction: column;
      gap: 20px;
    }
    
    .quote-company-info {
      flex-direction: column;
    }
    
    .quote-actions {
      flex-direction: column;
      gap: 10px;
    }
    
    .back-btn, .create-btn {
      width: 100%;
    }
    
    .delivery-method-selector {
      flex-direction: column;
    }
    
    .calendar-with-navigation {
      flex-direction: column;
    }
    
    .calendar-nav-button {
      position: static;
      transform: none;
      margin: 10px 0;
    }
    
    .prev-button {
      margin-right: auto;
    }
    
    .next-button {
      margin-left: auto;
    }
    
    .react-datepicker__month-container {
      width: 100%;
    }
    
    /* Sur mobile, n'afficher qu'un mois à la fois */
    .react-datepicker__month-container + .react-datepicker__month-container {
      display: none;
    }
    
    .selected-dates-summary {
      flex-direction: column;
      gap: 10px;
    }
  }



  /* Dans CreateQuotePage.css */
.payment-method-selector {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .payment-option {
    flex: 1;
    border: 2px solid #eee;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .payment-option:hover {
    border-color: #ddd;
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.05);
  }
  
  .payment-option.selected {
    border-color: #C9A77C;
    background-color: rgba(201, 167, 124, 0.1);
  }
  
  .payment-option h3 {
    margin: 10px 0;
    color: #333;
  }
  
  .payment-option p {
    color: #666;
    margin: 0;
  }
  
  .bank-details {
    background-color: #f8f9fa;
    border-radius: 6px;
    padding: 15px;
    margin-top: 15px;
  }
  
  .bank-details p {
    margin: 5px 0;
  }



/* Dans CreateQuotePage.css */
.product-preview {
  margin-top: 15px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  display: flex;
  gap: 20px;
  border: 1px solid #e9ecef;
}

.product-preview-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 40%;
}

.product-thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s;
  cursor: pointer;
}

.product-thumbnail:hover {
  transform: scale(1.05);
}

.product-preview-info {
  flex: 1;
}

.product-preview-info h3 {
  color: #333;
  margin-top: 0;
  margin-bottom: 10px;
}

.product-description {
  color: #666;
  margin-bottom: 15px;
  font-size: 0.9rem;
}

.no-image {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
  color: #999;
  font-size: 12px;
  text-align: center;
  border-radius: 4px;
}

.availability-info {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: rgba(201, 167, 124, 0.1);
  border-left: 3px solid #C9A77C;
  border-radius: 4px;
  font-size: 0.9rem;
}

/* Responsive */
@media (max-width: 768px) {
  .product-preview {
    flex-direction: column;
  }
  
  .product-preview-images {
    max-width: 100%;
  }
}



.product-option {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.product-option-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 10px;
}

.product-option-placeholder {
  width: 40px;
  height: 40px;
  background-color: #eee;
  border-radius: 4px;
  margin-right: 10px;
}

.product-option-details {
  display: flex;
  flex-direction: column;
}

.product-option-name {
  font-weight: 500;
}

.product-option-price {
  font-size: 0.85rem;
  color: #666;
}

.product-dropdown-container {
  display: flex;
  gap: 10px;
}

.product-dropdown {
  flex-grow: 1;
}


/* Dans CreateQuotePage.css */
.unavailable-product {
  background-color: rgba(255, 0, 0, 0.1);
}

.stock-warning {
  color: #d9534f;
  font-size: 0.85em;
  font-weight: normal;
  margin-left: 5px;
}

/* Pour customiser davantage l'apparence des options dans React-Select */
.product-option {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.product-option-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 10px;
}

.product-option-placeholder {
  width: 40px;
  height: 40px;
  background-color: #eee;
  border-radius: 4px;
  margin-right: 10px;
}

.product-option-details {
  display: flex;
  flex-direction: column;
}

.product-option-name {
  font-weight: 500;
}

.product-option-price {
  font-size: 0.85rem;
  color: #666;
}


.client-info-editable .client-details-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.client-info-editable .form-row {
  display: flex;
  gap: 1rem;
}

.client-info-editable .form-group {
  flex: 1;
}

.client-info-editable label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.client-info-editable input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
}

.client-info-editable input:focus {
  outline: none;
  border-color: #C9A77C;
  box-shadow: 0 0 0 2px rgba(201, 167, 124, 0.1);
}

@media (max-width: 768px) {
  .client-info-editable .form-row {
    flex-direction: column;
    gap: 1rem;
  }
}